import React from "react"
import { Link } from "gatsby"
import { GatsbyImage, getImage, StaticImage } from "gatsby-plugin-image"

// import service1 from "../../images/home/service-1.webp"
import service2 from "../../images/home/service-2.webp"

const OurCapabilities = ({ service1Img, service2Img, service3Img }) => {
  return (
    <div class="section-full bg-capabilities content-inner">
      <div class="container">
        <div className="section-head text-center">
          <h2 className="title mt-4">Our Capabilities</h2>
        </div>
        <div class="row">
          <div
            class="col-lg-4 wow bounceInUp"
            data-wow-duration="2s"
            data-wow-delay="0.6s"
          >
            <div class="blog-post blog-grid blog-rounded blog-effect1">
              <div class="dlab-post-media dlab-post-media-home">
                <Link to="/products-and-services/services/novel-diagnostics">
                  <GatsbyImage
                    image={getImage(service1Img.images)}
                    alt="CMO Photo"
                    className="capab-img"
                  />
                </Link>
              </div>
              <div class="dlab-info p-a25 border-1 info-capabilities">
                <div class="dlab-post-title">
                  <h4 class="post-title">
                    <Link to="/products-and-services/services/novel-diagnostics">
                      Novel Diagnostics
                    </Link>
                  </h4>
                </div>
                <div class="dlab-post-text">
                  <p>
                    One of our precision oncology test Mammaprint, was the first
                    FDA cleared IVDMIA breast cancer recurrence assay.
                  </p>
                </div>
                <div class="dlab-post-readmore">
                  <Link
                    to="/products-and-services/services/novel-diagnostics"
                    title="READ MORE"
                    rel="bookmark"
                    class="site-button btnhover20"
                  >
                    READ MORE
                  </Link>
                </div>
              </div>
            </div>
          </div>
          <div
            class="col-lg-4 wow bounceInUp"
            data-wow-duration="2s"
            data-wow-delay="0.6s"
          >
            <div class="blog-post blog-grid blog-rounded blog-effect1">
              <div class="dlab-post-media dlab-post-media-home">
                <Link to="/products-and-services/services/therapeutics">
                  <GatsbyImage
                    image={getImage(service2Img.images)}
                    alt="CMO Photo"
                    className="capab-img"
                  />
                </Link>
              </div>
              <div class="dlab-info p-a25 border-1 info-capabilities">
                <div class="dlab-post-title">
                  <h4 class="post-title">
                    <Link to="/products-and-services/services/therapeutics">
                      Therapeutics
                    </Link>
                  </h4>
                </div>
                <div class="dlab-post-text">
                  <p>
                    We have various drugs in key therapeutics areas such as
                    biosimilars, novel cancer drugs and diagnostics, which are
                    commercialized in most ASEAN countries.
                  </p>
                </div>
                <div class="dlab-post-readmore">
                  <Link
                    to="/products-and-services/services/therapeutics"
                    title="READ MORE"
                    rel="bookmark"
                    class="site-button btnhover20"
                  >
                    READ MORE
                  </Link>
                </div>
              </div>
            </div>
          </div>
          <div
            class="col-lg-4 wow bounceInUp"
            data-wow-duration="2s"
            data-wow-delay="0.6s"
          >
            <div class="blog-post blog-grid blog-rounded blog-effect1">
              <div class="dlab-post-media dlab-post-media-home">
                <Link to="/products-and-services/services/other-services">
                  <GatsbyImage
                    image={getImage(service3Img.images)}
                    alt="CMO Photo"
                    className="capab-img"
                  />
                </Link>
              </div>
              <div class="dlab-info p-a25 border-1 info-capabilities">
                <div class="dlab-post-title">
                  <h4 class="post-title">
                    <Link to="/products-and-services/services/other-services">
                      Other Services
                    </Link>
                  </h4>
                </div>
                <div class="dlab-post-text">
                  <p>
                    We welcome collaborations in clinical development with
                    companies which have strong science-based pipeline. We and
                    our sister companies also provide laboratory services such
                    as BA (Bioavailability) and BE (Bioequivalence) studies, CMO
                    for biologics, and diagnostic services including biomarker.
                  </p>
                </div>
                <div class="dlab-post-readmore">
                  <Link
                    to="/products-and-services/services/other-services"
                    title="READ MORE"
                    rel="bookmark"
                    class="site-button btnhover20"
                  >
                    READ MORE
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* <div class="row justify-content-md-center">
          <div
            class="col-lg-4 wow bounceInUp"
            data-wow-duration="2s"
            data-wow-delay="0.6s"
          >
            <div class="blog-post blog-grid blog-rounded blog-effect1">
              <div class="dlab-post-media dlab-post-media-home">
                <Link to="/products-and-services/services/vaccines">
                  <GatsbyImage
                    image={getImage(service2Img.images)}
                    alt="CMO Photo"
                    className="capab-img"
                  />
                </Link>
              </div>
              <div class="dlab-info p-a20 border-1">
                <div class="dlab-post-title">
                  <h4 class="post-title">
                    <Link to="/products-and-services/services/vaccines">
                      Vaccines
                    </Link>
                  </h4>
                </div>
                <div class="dlab-post-text">
                  <p>
                    Lorem ipsum dolor sit amet consectetur adipisicing elit.
                    Possimus alias dolorem aspernatur nemo praesentium. Itaque,
                    commodi hic! Molestiae, temporibus magni!¸
                  </p>
                </div>
                <div class="dlab-post-readmore">
                  <Link
                    to="/products-and-services/services/vaccines"
                    title="READ MORE"
                    rel="bookmark"
                    class="site-button btnhover20"
                  >
                    READ MORE
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div> */}
      </div>
    </div>
  )
}

export default OurCapabilities
