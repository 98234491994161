import React, { Component } from "react"
// import { Link } from 'react-router-dom';
import Slider from "react-slick"
import responsive from "../slider-resonsive"
import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"

import content from "./homeTestiItem"

class HomeTestimonial extends Component {
  render() {
    const settings = {
      dots: false,
      infinite: true,
      speed: 500,
      slidesToShow: 2,
      slidesToScroll: 1,
      autoplay: false,
      responsive: responsive(2, 2, 1),
    }
    return (
      <>
        <Slider
          {...settings}
          className="testimonial-five primary dots-style-3 btn-style-2 out btn-color"
        >
          {content.map((item, id) => (
            <div
              class="item wow fadeInUp"
              data-wow-duration="2s"
              data-wow-delay="0.3s"
            >
              <div class="testimonial-14 quote-left testimonial-container">
                <div class="testimonial-detail clearfix text-white testi-detail">
                  <h5 class="testimonial-name m-t10 m-b5">{item.author}</h5>
                  <span class="home-testi testimonial-position">
                    {item.designation}
                  </span>
                </div>
                <div class="testimonial-text testi-desc">
                  <p className="text-center">{item.description}</p>
                </div>
              </div>
            </div>
          ))}
        </Slider>
      </>
    )
  }
}
export default HomeTestimonial
