import React, { Component } from "react"
import Slider from "react-slick"
import responsive from "../slider-resonsive"
import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"
import content from "./career-testimonial-items"

class CareerTestimonial extends Component {
  render() {
    const settings = {
      dots: true,
      infinite: true,
      speed: 500,
      slidesToShow: 3,
      slidesToScroll: 1,
      autoplay: false,
      responsive: responsive(),
    }
    return (
      <>
        <Slider
          {...settings}
          className="testimonial-two-dotsl dots-long d-primary btn-style-1"
        >
          {content.map((item, id) => (
            <div
              class="item p-a5 wow fadeInUp"
              data-wow-duration="2s"
              data-wow-delay="0.3s"
            >
              <div class="testimonial-9 flex-fill testimonial-container" style={{ minHeight: '650px' }}>
                <div class="testimonial-pic radius style1 testi-photo">
                  <img
                    src={item.image}
                    id={item.author === "Ratna Sosialin" ? "flip-img" : ""}
                  />
                </div>
                <div class="testimonial-text testi-desc">
                  <p className="text-center">{item.description}</p>
                </div>
                <div class="testimonial-detail testi-detail">
                  <strong class="testimonial-name">{item.author}</strong>
                  <span class="testimonial-position">{item.designation}</span>
                </div>
              </div>
            </div>
          ))}
        </Slider>
      </>
    )
  }
}
export default CareerTestimonial
