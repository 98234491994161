import React from "react"
import { StaticImage } from "gatsby-plugin-image"

import ratna from "../../images/career-images/ratna-sosialin.webp"
import carl from "./../../images/career-images/christopher-carl.webp"
// import catherine from "../../images/career-images/catherine.webp"
import yadil from "../../images/career-images/yadil.webp"
import fendy from "../../images/career-images/fendy.webp"
import nur from "../../images/career-images/nur-azizah.webp"
import camel from "../../images/career-images/camel.webp"
import steven from "../../images/career-images/steven.webp"
import epin from "../../images/career-images/epin.webp"
import anne from "../../images/career-images/anne.webp"
import jessica from "../../images/career-images/jessica-sunardi.webp"
import anton from "../../images/career-images/anton.webp"
import vincent from "../../images/career-images/vincent.webp"
import yuwono from "../../images/career-images/yuwono.webp"
import elfrada from "../../images/career-images/elfreda.webp"

import paritosh from "../../images/about/our-leader-team/Paritosh Keertikar.webp"
import catherine from "../../images/about/our-leader-team/Catherine Natt.webp"
import ong from "../../images/about/our-leader-team/Ong Lay Fung.webp"

// const content = [
//   {
//     image: ratna,
//     author: "Ratna Sosialin",
//     designation: "Ex Quality Senior GM",
//     description:
//       "Majority employees are young people graduated from local and overseas universities who are IT literate and motivated.",
//   },
//   {
//     image: carl,
//     author: "Christopher Carl Sweeney",
//     designation: "Ex Associate Director",
//     description:
//       "Kalbio success in building the highest quality possible is mainly because of the team’s “can do” attitude. They rose to every challenge and often exceeded the required standards. I highly recommend Kalbio team to anyone looking for a technical, fully GMP compliant partner.",
//   },
//   {
//     image: catherine,
//     author: "Catherine Lesmana",
//     designation: "Ex Production Scientist",
//     description:
//       "Working for Kalbio had been a life changing experience for me. Everyday there were new challenges to face, new knowledge to gain, and new friendships made. Of course, solid teamwork made it all possible.",
//   },
//   {
//     image: yadil,
//     author: "Yadil Andi Beddu",
//     designation: "Senior Manager HRGA-HSSE-IT",
//     description:
//       "I have been working for Kalbio for almost five years now. It is a great honor to work with a group of such a dynamic, smart and young talents in Biopharmaceutical and Biotechnology",
//   },
//   {
//     image: fendy,
//     author: "Aditya Fendy Heryanto",
//     designation: "Project Manager",
//     description:
//       "A lot of new things can be learned here. The daily work can also be quite challenging. However, I joined an energetic team that enables me to excel myself and I genuinely enjoy working here.",
//   },
//   {
//     image: nur,
//     author: "Nur Azizah Sitompul",
//     designation: "Ex HR Dept Intern from Indonesian University",
//     description: `It's such a wonderful time working here, I gained a lot of knowledge & hands-on experience from the experts! I had the opportunity to be creative, make autonomous decisions, and hone my soft skills. Kalbio has a supportive & encouraging environment.`,
//   },
//   {
//     image: camel,
//     author: "Camelia Evi Salamah",
//     designation: "PPIC Supervisor",
//     description:
//       "Working with many talented people in KGM has been a pleasure for me. I got to experience many extraordinary things with them and learned a lot.",
//   },
//   {
//     image: steven,
//     author: "Steven Amadeus",
//     designation: "IT Supervisor",
//     description: `Kalbio gives me the opportunity to learn and grow along with the company. I learn lots of technical, managerial, and other soft skills. Kalbio's team is young, motivated, and helpful.`,
//   },
//   {
//     image: epin,
//     author: "Josephine Christine Utomo",
//     designation: "Ex Production Scientist",
//     description: `Kalbio is a company filled with young professionals that are competent and willing to grow with the company. Working for Kalbio helped me to grow so much both personally and professionally.`,
//   },
//   {
//     image: anne,
//     author: "Anne Gabriella Sondakh",
//     designation: "Production Scientist",
//     description: `Jumping into a working environment where everyone is willing to share not only their knowledge and experiences but also their kindness and friendship have been a privilege I never thought I could experience before.`,
//   },
//   {
//     image: jessica,
//     author: "Jessica Sunardi",
//     designation: "Ex R&D Dept Intern from Pelita harapan University",
//     description: `The best thing about this internship is the hands-on experience when carrying out laboratory works. The skills and knowledge I gained are immeasurable. I couldn’t have asked for a better first internship experience.`,
//   },
//   {
//     image: anton,
//     author: "Anton Suwandoro",
//     designation: "Engineering Manager",
//     description: `Having been working for KGM for 7 years has developed my technical skill a lot. So challenging as KGM was the first biotechnology facility in Indonesia. I grow with KGM. It’s Fun, Family and Proud.`,
//   },
//   {
//     image: vincent,
//     author: "Vincent Cunardy",
//     designation: "IT Dept Intern from Bina Nusantara University",
//     description: `Working at Kalbio is a worthwhile experience. Here I was given an opportunity to partake in a big project, where I could expand my knowledge, skill, and connection in the comfortable working environment.`,
//   },
//   {
//     image: yuwono,
//     author: "YUWONO WIBOWO",
//     designation: "Ex Production Supervisor",
//     description: `It's been a privilege working in one of the pioneer of biopharmaceutical industry in Indonesia. The learning curve is exponential as you are exposed to state of the art industrial biotech equipment, plus the working environment is fun!`,
//   },
//   {
//     image: elfrada,
//     author: "NATHANIA ELFREDA",
//     designation: "R&D Staff",
//     description: `Working here has allow me to do something I am passionate about. Being a part of fast-paced enviroment where I’m able to grow professionally. I’m fortunate to be surrounded by a great team who are willing to share their knowledge.`,
//   },
// ]

const content = [
  {
    image: ong,
    author: "Ong Lay Fung",
    designation: "Senior RA / QA Manager",
    description:
      "Love the dynamic work culture where every team member is given the opportunity to excel in what they do best and grow their career and positions in IGK.",
  },
  {
    image: catherine,
    author: "Catherine Natt",
    designation: "Senior Commercial – Supply Chain and QA Manager",
    description:
      "Working for Innogene has been a great experience. Over the past 9 years, I have been developed professionally into many new areas. It’s challenging and I need to think and act outside my comfort zone. What i like most from this company is the perceptive superior, conducive & demanding working environment and healthy work-life balance.",
  },
  {
    image: paritosh,
    author: "Paritosh Keertikar",
    designation: "Assistant Director - Clinical Affairs",
    description:
      "Having been with Innogene Kalbiotech for 15 years, almost from it’s inception, I can affirm without any doubt that it has been an exhilarating, challenging, difficult yet fun and successful journey. What has always been constant so far is the kind, demanding but always benevolent support and approach of company owners and management at all levels and that is a rarity in large organizations today.",
  },
  // {
  //   image: avatar,
  //   author: "Yadil Andi Beddu",
  //   designation: "Senior Manager HRGA-HSSE-IT",
  //   description:
  //     "I have been working for Kalbio for almost five years now. It is a great honor to work with a group of such a dynamic, smart and young talents in Biopharmaceutical and Biotechnology",
  // },
  // {
  //   image: avatar,
  //   author: "Aditya Fendy Heryanto",
  //   designation: "Project Manager",
  //   description:
  //     "A lot of new things can be learned here. The daily work can also be quite challenging. However, I joined an energetic team that enables me to excel myself and I genuinely enjoy working here.",
  // },
  // {
  //   image: avatar,
  //   author: "Nur Azizah Sitompul",
  //   designation: "Ex HR Dept Intern from Indonesian University",
  //   description: `It's such a wonderful time working here, I gained a lot of knowledge & hands-on experience from the experts! I had the opportunity to be creative, make autonomous decisions, and hone my soft skills. Kalbio has a supportive & encouraging environment.`,
  // },
  // {
  //   image: avatar,
  //   author: "Camelia Evi Salamah",
  //   designation: "PPIC Supervisor",
  //   description:
  //     "Working with many talented people in KGM has been a pleasure for me. I got to experience many extraordinary things with them and learned a lot.",
  // },
  // {
  //   image: avatar,
  //   author: "Steven Amadeus",
  //   designation: "IT Supervisor",
  //   description: `Kalbio gives me the opportunity to learn and grow along with the company. I learn lots of technical, managerial, and other soft skills. Kalbio's team is young, motivated, and helpful.`,
  // },
  // {
  //   image: avatar,
  //   author: "Josephine Christine Utomo",
  //   designation: "Ex Production Scientist",
  //   description: `Kalbio is a company filled with young professionals that are competent and willing to grow with the company. Working for Kalbio helped me to grow so much both personally and professionally.`,
  // },
  // {
  //   image: avatar,
  //   author: "Anne Gabriella Sondakh",
  //   designation: "Production Scientist",
  //   description: `Jumping into a working environment where everyone is willing to share not only their knowledge and experiences but also their kindness and friendship have been a privilege I never thought I could experience before.`,
  // },
  // {
  //   image: avatar,
  //   author: "Jessica Sunardi",
  //   designation: "Ex R&D Dept Intern from Pelita harapan University",
  //   description: `The best thing about this internship is the hands-on experience when carrying out laboratory works. The skills and knowledge I gained are immeasurable. I couldn’t have asked for a better first internship experience.`,
  // },
  // {
  //   image: avatar,
  //   author: "Anton Suwandoro",
  //   designation: "Engineering Manager",
  //   description: `Having been working for KGM for 7 years has developed my technical skill a lot. So challenging as KGM was the first biotechnology facility in Indonesia. I grow with KGM. It’s Fun, Family and Proud.`,
  // },
  // {
  //   image: avatar,
  //   author: "Vincent Cunardy",
  //   designation: "IT Dept Intern from Bina Nusantara University",
  //   description: `Working at Kalbio is a worthwhile experience. Here I was given an opportunity to partake in a big project, where I could expand my knowledge, skill, and connection in the comfortable working environment.`,
  // },
  // {
  //   image: avatar,
  //   author: "YUWONO WIBOWO",
  //   designation: "Ex Production Supervisor",
  //   description: `It's been a privilege working in one of the pioneer of biopharmaceutical industry in Indonesia. The learning curve is exponential as you are exposed to state of the art industrial biotech equipment, plus the working environment is fun!`,
  // },
  // {
  //   image: avatar,
  //   author: "NATHANIA ELFREDA",
  //   designation: "R&D Staff",
  //   description: `Working here has allow me to do something I am passionate about. Being a part of fast-paced enviroment where I’m able to grow professionally. I’m fortunate to be surrounded by a great team who are willing to share their knowledge.`,
  // },
]

export default content
