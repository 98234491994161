const content = [
  {
    author: `Zhao Yanqing`,
    designation: `President Director of Kexing Biopharm Co. Ltd`,
    description: `As a leading pharmaceutical enterprise in Southeast Asia, Innogene Kalbiotech (IGK) is one of our most important business partner in Southeast Asia, maintained a mutually beneficial relationship with us for about 20 years. IGK is a good partner with honest and practical style, I believe that we will continue to go hand in hand and expand the scope of cooperation in the future to make a contribution to Indonesia and the whole human health with the support of the friendship bridge between China and Indonesia.`,
  },
  {
    author: `Susheel Umesh`,
    designation: `Chief Commercial Officer, Emerging Markets, Biocon Biologics`,
    description: `We are glad that through Kalbe we are able to address unmet needs of breast cancer patients through our affordable Trastuzumab which is a leading brand in its segment in Indonesia.
    We look forward to strengthening this synergistic partnership and expanding our product portfolio to enhance access to patients, in Indonesia.`,
  },
]

export default content
