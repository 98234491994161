import React, { Component, useState } from "react"
import { Link } from "gatsby"
import Header from "../layouts/header"
import Footer from "../components/custom-components/FooterCream"
import { blogContent3 } from "../components/SliderContent"
import { Parallax } from "react-parallax"
import FloatingBtn from "../components/custom-components/floatingBtn"
import HomeTestimonial from "../components/custom-components/HomeTestimonial"
import { GatsbyImage, getImage, StaticImage } from "gatsby-plugin-image"
import OurCapabilities from "../components/custom-components/OurCapabilities"
import ScrollToTop from "../components/scrollToTop"
import CareerTestimonial from "./../components/custom-components/careerTestimonial"

import "../css/plugins.css"
import "../css/style.css"
import "../css/skin/skin-1.css"
import "../css/templete.min.css"
import "../css/custom-css/hanif-main.css"
import "../css/custom-css/custom-index.css"
import "../css/custom-css/hanif-main.css"

import bg4 from "../images/landing-page-banner.webp"

import ourCapabilities1 from "../images/our-work/pic1.jpg"
import ourCapabilities2 from "../images/our-work/pic2.jpg"
import ourCapabilities3 from "../images/our-work/pic3.jpg"
import NewsCard from "../components/custom-components/LastNewsCard"
import NewsListLayout from "../layouts/news-event-template"
import { StaticQuery, graphql } from "gatsby"
import ProductsSlider from "../components/custom-components/ProductsSlider"
import SEO from "../components/seo"

import HomeBanner from "../components/custom-components/HomeBanner"
import { HomeBannerSliderContent } from "../components/SliderContent"

const Index = ({ data }) => {
  const {
    service1,
    service2,
    service3,
    joinUsLeft,
    joinUsRight,
    joinUsCenter,
    // image1,
    // image2,
    // image3,
  } = data

  // const capabilites = [
  //   {
  //     title: "Cell Therapy",
  //     description:
  //       "Lorem, ipsum dolor sit amet consectetur adipisicing elit. Dolore ut doloremque hic laborum dignissimos ducimus. Laborum sit earum nisi quaerat?",
  //     image: image2.image,
  //     slug: "cell-therapy",
  //   },
  //   {
  //     title: "Diagnostics",
  //     description:
  //       "Lorem, ipsum dolor sit amet consectetur adipisicing elit. Dolore ut doloremque hic laborum dignissimos ducimus. Laborum sit earum nisi quaerat?",
  //     image: image3.image,
  //     slug: "diagnostics",
  //   },
  //   {
  //     title: "Therapeutics",
  //     description:
  //       "Lorem, ipsum dolor sit amet consectetur adipisicing elit. Dolore ut doloremque hic laborum dignissimos ducimus. Laborum sit earum nisi quaerat?",
  //     image: image2.image,
  //     slug: "therapeutics",
  //   },
  //   {
  //     title: "Vaccines",
  //     description:
  //       "Lorem, ipsum dolor sit amet consectetur adipisicing elit. Dolore ut doloremque hic laborum dignissimos ducimus. Laborum sit earum nisi quaerat?",
  //     image: image3.image,
  //     slug: "vaccines",
  //   },
  //   {
  //     title: "Other Services",
  //     description:
  //       "Lorem, ipsum dolor sit amet consectetur adipisicing elit. Dolore ut doloremque hic laborum dignissimos ducimus. Laborum sit earum nisi quaerat?",
  //     image: image2.image,
  //     slug: "other-services",
  //   },
  // ]

  // console.log(capabilites)

  return (
    <div className="skin-1 index">
      <SEO title="Home" />
      <Header indexPage />
      <FloatingBtn />
      <HomeBanner data={HomeBannerSliderContent} hideBtn />

      <div
        class="section-full call-action style1 wow fadeIn"
        data-wow-duration="2s"
        data-wow-delay="0.2s"
      >
        <div class="container">
          <div class="row">
            <div class="col-lg-9">
              <h2 class="title">Innogene Kalbiotech at a Glance</h2>
              <p className="kalbio-glance-txt">
                A biotechnology company focusing on novel biologics, biosimilars
                and novel diagnostics. A part of Kalbe Group and a subsidiary
                company of PT Kalbe Genexine Biologics (KGbio).
              </p>
            </div>
            <div class="col-lg-3 d-flex">
              <Link
                to="/about"
                class="site-button black align-self-center ml-auto btnhover14"
              >
                Find Out More
              </Link>
            </div>
          </div>
        </div>
      </div>
      {/* 
      <div class="section-full content-inner bg-white">
        <div class="container">
          <div class="section-head style2 text-center">
            <h2 class="title">Leading The Way in Asia</h2>
            <p>
              With our expertise, experience and familiarity with the local
              regulatory environments, we can help you navigate the uncharted
              waters of diverse nations and de-mystify this complex market that
              is Asia.
            </p>
            <Link to="/about" class="site-button black btnhover14">
              Find Out More
            </Link>
          </div>
          <HomeTestimonial />
        </div>
      </div> */}

      <OurCapabilities
        service1Img={service1}
        service2Img={service2}
        service3Img={service3}
      />

      {/* <div
        className="section-full content-inner bg-capabilities wow fadeIn"
        data-wow-duration="2s"
        data-wow-delay="0.4s"
      >
        <div className="container">
          <div className="section-head text-center">
            <h2 className="title">Our Latest News</h2>
          </div>

          <ProductsSlider
            data={capabilites}
            blogPostCls="post-style-1"
            imgEffect="rotate"
          />
        </div>
      </div> */}

      <div class="section-full bg-gray content-inner">
        <div class="container">
          <div className="section-head text-center">
            <h2 className="title">What People Are Saying</h2>
          </div>
          <HomeTestimonial />
        </div>
      </div>

      <div
        className="section-full content-inner bg-news wow fadeIn"
        data-wow-duration="2s"
        data-wow-delay="0.4s"
      >
        <div className="container">
          <div className="section-head text-center">
            <h2 className="title">Latest News</h2>
          </div>

          <NewsCard
            data={data.news.nodes}
            blogPostCls="post-style-1"
            imgEffect="rotate"
            noDes
          />
          {/* <NewsCard data={data.ew}/> */}

          {/* <NewsListLayout lastNews>
            <div class="row">
              {data.news.nodes.map(singleNews => {
                return <NewsCard data={singleNews} lastNews />
              })}
            </div>
          </NewsListLayout> */}
        </div>
      </div>

      <div className="join-us">
        <div class="page-content" style={{ backgroundColor: "#FFFFF0" }}>
          <div class="content-block">
            <div class="section-full">
              {/* <div className="p-4">
                <div className="row d-flex mb-5">
                  <div className="col-xl-2 col-6">
                    <GatsbyImage image={image3.image.gatsbyImageData} />
                  </div>
                  <div className="col-xl-2 col-6">
                    <GatsbyImage image={image3.image.gatsbyImageData} />
                  </div>

                  <div className="col-xl-4 col-12 d-flex flex-column align-content-center justify-content-center mt-5">
                    <h4 style={{ color: "#7BA513" }}>
                      Therapeutic, Diagnostics & Research opportunities in
                      Asia's emerging markets are opening up!
                    </h4>
                    <Link
                      className="text-right site-button align-self-center ml-auto btnhover14"
                      style={{ color: "#FFFFFF" }}
                    >
                      Talk to Us
                    </Link>
                  </div>
                  <div className="col-xl-2 col-6">
                    <GatsbyImage image={image3.image.gatsbyImageData} />
                  </div>
                  <div className="col-xl-2 col-6">
                    <GatsbyImage image={image3.image.gatsbyImageData} />
                  </div>
                </div>
              </div> */}

              <div class="row spno about-industry">
                <div
                  class="col-lg-4 wow fadeIn"
                  data-wow-duration="2s"
                  data-wow-delay="0.6s"
                >
                  <div class="dlab-post-media">
                    <Link to={"/career-page"}>
                      <GatsbyImage image={getImage(joinUsLeft.images)} />
                    </Link>
                  </div>
                </div>
                <div
                  class="col-lg-4 wow fadeIn"
                  data-wow-duration="2s"
                  data-wow-delay="0.6s"
                >
                  <div class="dlab-post-media">
                    <Link to={"/career-page"}>
                      <GatsbyImage image={getImage(joinUsCenter.images)} />
                    </Link>
                  </div>
                </div>
                <div
                  class="col-lg-4 wow fadeIn"
                  data-wow-duration="2s"
                  data-wow-delay="0.2s"
                >
                  <div class="dlab-post-media">
                    <Link to={"/career-page"}>
                      <GatsbyImage image={getImage(joinUsRight.images)} />
                    </Link>
                  </div>
                </div>
              </div> 
            </div>
          </div>
        </div>
      </div>

      <Footer hideContactInfo />
      <ScrollToTop className="icon-up" />
    </div>
  )
}

export default Index

export const query = graphql`
  query LastNews {
    news: allContentfulNewsevents(
      limit: 6
      sort: { fields: newsEventsDate, order: DESC }
    ) {
      nodes {
        id
        title
        createdAt(formatString: "DD MMMM YYYY")
        images {
          gatsbyImageData(layout: CONSTRAINED, height: 270, width: 405)
        }
        slug
        description {
          raw
        }
        newsType
        eventsType
        descSingkat
        newsEventsDate(formatString: "DD MMMM YYYY")
      }
    }
    service1: contentfulHomeImages(title: { eq: "services-1" }) {
      title
      images {
        gatsbyImageData(layout: FULL_WIDTH)
      }
    }
    service2: contentfulHomeImages(title: { eq: "services-4" }) {
      title
      images {
        gatsbyImageData(layout: FULL_WIDTH)
      }
    }
    service3: contentfulHomeImages(title: { eq: "services-3" }) {
      title
      images {
        gatsbyImageData(layout: FULL_WIDTH)
      }
    }
    joinUsLeft: contentfulHomeImages(title: { eq: "join-us-left" }) {
      title
      images {
        gatsbyImageData
      }
    }
    joinUsRight: contentfulHomeImages(title: { eq: "join-us-right" }) {
      title
      images {
        gatsbyImageData
      }
    }
    joinUsCenter: contentfulHomeImages(title: { eq: "join-us-center" }) {
      title
      images {
        gatsbyImageData
      }
    }

    image1: contentfulServices(title: { eq: "cmo-1" }) {
      image {
        gatsbyImageData
      }
    }
    image2: contentfulServices(title: { eq: "cmo-2" }) {
      image {
        gatsbyImageData
      }
    }
    image3: contentfulServices(title: { eq: "cmo-3" }) {
      image {
        gatsbyImageData
      }
    }
  }
`
